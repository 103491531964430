import(/* webpackMode: "eager", webpackExports: ["EmergencyBanner"] */ "/vercel/path0/site/components/layout/EmergencyBanner/EmergencyBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataDogInit"] */ "/vercel/path0/site/components/scripts/DataDogInit/DataDogInit.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/components/shared/DraftIndicator/DraftIndicator.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/site/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/font/local/target.css?{\"path\":\"fonts/loadFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/PPFormula-CondensedBlack.woff2\",\"weight\":\"900\",\"variable\":\"--font-ppformula\"}],\"variableName\":\"ppFormula\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/font/google/target.css?{\"path\":\"fonts/loadFonts.ts\",\"import\":\"Barlow\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"variable\":\"--font-barlow\"}],\"variableName\":\"fontBarlow\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/styles/style.scss");
